import React, { useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Menu } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import classNames from 'classnames'
import store from 'store'
import { find } from 'lodash'
import { TEXT } from 'constant'
import PATH from 'path.config'
import { renderDraft } from 'components/Helper'
import { FormattedMessage } from 'react-intl'
import { history } from 'App'
import style from './style.module.scss'

const mapStateToProps = ({ menu, settings, user }) => ({
  menuData: menu.menuData,
  logo: settings.logo,
  menuColor: settings.menuColor,
  role: user.role,
  user: user,
})

const MenuTop = ({
  menuData = [],
  location: { pathname },
  user,
  // menuColor,
  // logo,
  role,
  transparent,
}) => {
  const settings = useSelector((state) => state.Project.settings)
  const dispatch = useDispatch()
  const [selectedKeys, setSelectedKeys] = useState(store.get('app.menu.selectedKeys') || [])

  useEffect(() => {
    applySelectedKeys()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menuData])

  const applySelectedKeys = () => {
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    const selectedItem = find(flattenItems(menuData, 'children'), ['url', pathname])
    setSelectedKeys(selectedItem ? [selectedItem.key] : [])
  }

  const handleClick = (e) => {
    store.set('app.menu.selectedKeys', [e.key])
    setSelectedKeys([e.key])
  }

  const logout = (e) => {
    e.preventDefault()
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  const moveToUrl = (url) => {
    if (url) history.push(url)
  }

  const generateMenuItems = () => {
    const generateItem = (item) => {
      const { key, title, url, icon, disabled, count } = item
      if (item.category) {
        return null
      }
      if (item.url) {
        return (
          <Menu.Item key={key} disabled={disabled}>
            {item.refTarget && (
              <a href={url} target={item.refTarget} rel="noopener noreferrer">
                {icon && <span className={`${icon} ${style.icon}`} />}
                <span className={style.title}>{title}</span>
                {count && <span className="badge badge-success ml-2">{count}</span>}
              </a>
            )}
            {!item.refTarget && (
              <Link to={url}>
                {icon && <span className={`${icon} ${style.icon}`} />}
                <span className={style.title}>{title}</span>
                {count && <span className="badge badge-success ml-2">{count}</span>}
              </Link>
            )}
          </Menu.Item>
        )
      }
      return (
        <Menu.Item key={key} disabled={disabled}>
          {icon && <span className={`${icon} ${style.icon}`} />}
          <span className={style.title}>{title}</span>
          {count && <span className="badge badge-success ml-2">{count}</span>}
        </Menu.Item>
      )
    }
    const generateSubmenu = (items) =>
      items.map((menuItem) => {
        // console.log(menuItem)
        if (menuItem.children) {
          const subMenuTitle = (
            <span key={menuItem.key}>
              {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
              <span className={style.title}>{menuItem.title}</span>
              {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
            </span>
          )
          return (
            <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children)}
            </Menu.SubMenu>
          )
        }
        if (!menuItem.opened) {
          return null
        }
        return generateItem(menuItem)
      })
    return menuData.map((menuItem) => {
      if (menuItem.roles && !menuItem.roles.includes(role)) {
        return null
      }
      if (menuItem.target === 'user' && !user.authorized) {
        return null
      }
      if (menuItem.target === 'guest' && user.authorized) {
        return null
      }
      if (menuItem.children) {
        const subMenuTitle = (
          <span key={menuItem.key}>
            {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
            <span className={style.title}>{menuItem.title}</span>
            {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
          </span>
        )
        return (
          <Menu.SubMenu
            title={subMenuTitle}
            key={menuItem.key}
            onTitleClick={() => {
              moveToUrl(menuItem.url)
            }}
          >
            {generateSubmenu(menuItem.children)}
          </Menu.SubMenu>
        )
      }
      return generateItem(menuItem)
    })
  }

  const renderUserMenu = () => {
    if (settings && settings.signInOpened === true && !user.authorized) {
      return (
        <div className={style.authContainer}>
          <Link
            to={PATH.AUTH.LOGIN}
            className={`${style.button} btn btn-rounded d-md-inline d-none`}
          >
            <FormattedMessage id="topBar.profileMenu.signIn" />
          </Link>
        </div>
      )
    }
    if (settings && settings.signInOpened === true && user.authorized) {
      return (
        <div className={style.authContainer}>
          {/* <Link to="/mypage" className={`${style.highlight} d-md-inline d-none`}>
          내 정보
        </Link> */}
          {/* <span className={`${style.highlight} d-md-inline d-none`}> | </span> */}
          <a
            href="#"
            onClick={logout}
            className={`${style.button} btn btn-rounded d-md-inline d-none`}
          >
            <FormattedMessage id="topBar.profileMenu.logout" />
          </a>
          <a
            href="#"
            onClick={logout}
            className={`${style.button}  btn btn-rounded d-inline d-md-none`}
          >
            <i className="fe fe-log-out" />
          </a>
        </div>
      )
    }
    return null
  }
  // const menuBackgroundColor = transparent ? '' : 'black'
  const renderLogo = () => {
    // console.log(settings)
    if (settings) {
      if (settings.largeLogoImageUrl) {
        // console.log('render logo image')
        return <img src={settings.largeLogoImageUrl} alt="logo" className={style.logoImage} />
      }
      return renderDraft(settings.pageHeader)
    }
    return TEXT.TITLE.TITLE
  }
  return (
    <div className={classNames(`${style.menu}`, { [style.dark]: !transparent })}>
      <div className={`${style.logoContainer}`}>
        <div className={style.logo}>
          <div className={style.name}>
            <Link to={PATH.HOME}>{renderLogo()}</Link>
          </div>
        </div>
      </div>
      <div className={style.navigation}>
        <Menu onClick={handleClick} selectedKeys={selectedKeys} mode="horizontal">
          {generateMenuItems()}
        </Menu>
      </div>
      {renderUserMenu()}
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(MenuTop))
