/* eslint-disable no-unused-vars */
import { all, takeEvery, takeLatest, put, call, fork, select } from 'redux-saga/effects'
import * as firebase from 'services/firebase'
// import moment from 'moment'
import _ from 'lodash'
import { rsFirebase, fireBase, fireStore } from '../../firebase'
import actions from './actions'

export function* SEND_MESSAGE({ payload }) {
  const now = fireBase.firestore.Timestamp.now()
  const messageId = `${now.seconds}-${now.nanoseconds}`

  const data = {
    id: messageId,
    roomId: payload.roomId,
    scheduleId: payload.schedule.id,
    message: payload.message,
    user: payload.user,
    deleted: false,
    highlight: false,
    createdTimestamp: now,
    createdAt: now.seconds,
    order: `${now.seconds}${now.nanoseconds}`,
  }
  const documentRef = `chat/${data.roomId}/messages/${messageId}/`
  yield call(rsFirebase.firestore.setDocument, documentRef, data, { merge: true })
}

export function* GET_MESSAGES({ payload }) {
  const { roomId, schedule, user, startDocId } = payload
  const isPublicChat = schedule.isPublicChat || false

  if (!roomId || schedule.chatEnabled === false) {
    return false
  }

  const collectionPath = `chat/${roomId}/messages`
  // const collectionRef = fireStore.collection(collectionPath)
  let collectionRef = null
  const lastRoomId = yield select((state) => state.chat.roomId)

  if (lastRoomId && lastRoomId !== roomId) {
    yield call({
      type: actions.CLEAR_MESSAGES,
      payload: null,
    })
  }

  if (startDocId) {
    const finished = yield select((state) => state.chat.finished)
    if (finished) {
      return null
    }

    // console.log(startDocId)
    collectionRef = fireStore
      .collection(collectionPath)
      .orderBy('order', 'desc')
      .startAfter(startDocId)
      .limit(50)
  } else {
    collectionRef = fireStore.collection(collectionPath).orderBy('order', 'desc').limit(50)
  }
  // if (!isPublicChat) {
  //   collectionRef = fireStore.collection(collectionPath).where('user.id', '==', user.id)
  // } else {
  //   collectionRef = fireStore.collection(collectionPath)
  // }
  const recentMessages = yield select((state) => state.chat.messages)
  // console.log('recent message', recentMessages)

  const successActionCreator = (data) => {
    // console.log(data.docs)
    let messageList = []
    // data.docChanges().forEach(change => {
    //   console.log(change)
    // })
    data.docs.forEach((message) => {
      if (isPublicChat) {
        messageList.push(message.data())
      } else if (
        message.data().user.id === user.id ||
        message.data().user.role === 'admin' ||
        user.role === 'admin'
      ) {
        messageList.push(message.data())
      }
    })

    messageList = _.sortBy(messageList, 'order', 'asc')
    // console.log('successActionCreator')
    // return {
    //   type: actions.SET_STATE,
    //   payload: { messages: messageList },
    // }
    let finished = false
    // if (
    //   recentMessages &&
    //   recentMessages.length > 0 &&
    //   messageList &&
    //   messageList.length > 0 &&
    //   recentMessages[0].order === messageList[0].order
    // ) {
    //   return null
    //   // finished = true
    //   // console.log('message load finished')
    // }
    // const concatMessage = _.concat(messageList, recentMessages)
    // console.log('lastRoomId', lastRoomId, 'roomId', roomId)

    // console.log({ messages: concatMessage, finished: finished, roomId: roomId })
    return {
      type: actions.SET_STATE,
      payload: { messages: messageList, finished: finished, roomId: roomId },
    }
  }
  const failureActionCreator = (error) => {
    console.log(error)
    return {
      type: actions.CLEAR_MESSAGES,
      payload: null,
    }
  }

  yield call(rsFirebase.firestore.syncCollection, collectionRef, {
    successActionCreator,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SEND_MESSAGE, SEND_MESSAGE),
    takeLatest(actions.GET_MESSAGES, GET_MESSAGES),
  ])
}
