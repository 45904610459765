// eslint-disable-next-line import/prefer-default-export
import React from 'react'
import draftToHtml from 'draftjs-to-html'
import _ from 'lodash'

export function numberToCurrency(value) {
  return `₩ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function linedText(text) {
  const newLineRegex = /(\r\n|\r|\n)/g
  if (typeof text !== 'string') {
    return text
  }
  return text.split(newLineRegex).map((line, index) => {
    if (line.match(newLineRegex)) {
      // eslint-disable-next-line react/no-array-index-key
      return <br key={`br-${index}`} />
    }
    return line
  })
}

export function linedTextToList(text) {
  const newLineRegex = /(\r\n|\r|\n)/g
  if (typeof text !== 'string') {
    return []
  }
  const newLinedTextList = []

  text.split(newLineRegex).forEach((line) => {
    // console.log('msatch', line.match(newLineRegex))
    // return newLinedText
    if (line.match(newLineRegex) === null) {
      if (line.length > 0) {
        newLinedTextList.push(line)
      }
    }
    // return line
  })
  return newLinedTextList
}

export function renderDraft(draft) {
  // console.log(speaker)
  if (!draft) {
    return ''
  }
  if (_.isString(draft)) {
    return draft
  }
  const html = draft ? draftToHtml(draft) : null
  // console.log(html)
  return <div className="draft" dangerouslySetInnerHTML={{ __html: html }} />
}

export function textFromDraft(draft) {
  // console.log(speaker)
  if (!draft) {
    return null
  }
  if (_.isString(draft)) {
    return draft
  }

  const html = draft ? draftToHtml(draft) : null
  return html.replace(/<[^>]+>/g, '')
}
