import localeAntd from 'antd/es/locale/ko_KR'

const messages = {
  'topBar.profileMenu.signIn': 'Sign in',
  'topBar.profileMenu.signUp': 'Registration',
  'topBar.profileMenu.myPage': 'My Page',
  'topBar.profileMenu.logout': 'Sign out',
  'menu.mobile.home': 'Home',

  'auth.signIn': 'Sign in',
  'auth.signUp': 'Registration',
  'auth.register': 'Registration',

  'auth.complete.success': 'Registration complete',
  'auth.complete.failed': 'Registration failed',

  'auth.confirm.title': 'Check Registration',
  'auth.confirm.message': '참가등록 시 입력한 이름과 메일로 신청여부를 확인 할 수 있습니다.',
  'auth.confirm.placeholder.name': 'Name',
  'auth.confirm.placeholder.email': 'E-mail',
  'auth.confirm.failed': '참가 등록 내역을 찾을 수 없습니다.',
  'auth.confirm.button': 'Registration',
  'auth.confirm.userInfo.status.green': 'Processing',
  'auth.confirm.userInfo.status.processing': 'Confirmed',
  'auth.confirm.userInfo.message': '참가 등록이 완료되었습니다.',
  'auth.confirm.userInfo.table.title': '참가 등록 내역',
  'auth.confirm.userInfo.table.name': '신청자',
  'auth.confirm.userInfo.table.email': '이메일',
  'auth.confirm.userInfo.table.registeredAt': '등록일시',
  'auth.confirm.userInfo.status': '상태',

  'auth.forgotPassword.title': 'Forgotten your password?',
  'auth.forgotPassword.message':
    "Please enter your email address and we'll send you a link to reset your password",
  'auth.forgotPassword.form.emailRequired': 'e-mail address is required',
  'auth.forgotPassword.placeholder.email': 'e-mail',
  'auth.forgotPassword.sendButton': 'Continue',

  'auth.information.title': 'Registration Instructions',
  'auth.information.button': 'Registration',

  'auth.login.title': 'Sign in',
  'auth.login.loading': 'Loading login form',
  'auth.login.blocked': 'Sign in is not allowed.',
  'auth.login.placeholder.email': 'E-Mail',
  'auth.login.placeholder.password': 'Password',
  'auth.login.placeholder.passwordConfirm': 'Confirm Password',
  'auth.login.passwordForgotLink': 'Forget Password?',
  'auth.login.submit': 'Sign in',
  'auth.login.requiredEmail': 'e-mail is required',
  'auth.login.requiredPassword': 'password is required.',

  'auth.signup.title': 'Registration',

  'auth.signup.login.title': 'Sign in information',
  'auth.signup.additional.title': 'Additional user information',

  'auth.signup.message.email': 'Please enter an',
  'auth.signup.message.email.1': 'e-mail',
  'auth.signup.message.email.2': ' address for ',
  'auth.signup.message.email.3': 'sign in ',
  'auth.signup.message.email.4': ' ',
  'auth.signup.placeholder.email': 'e-mail',
  'auth.signup.placeholder.password': 'Password',
  'auth.signup.placeholder.passwordConfirm': 'Confirm password',
  'auth.signup.passwordForgotLink': 'Forget password?',
  'auth.signup.requiredEmail': 'E-mail is required',
  'auth.signup.emailExists': 'E-Mail address already exists.',
  'auth.signup.isRequired': 'is required',
  'auth.signup.passwordHelp': 'Password must be at least 6 characters in length ',

  'auth.signup.requiredPassword': 'Password is required',
  'auth.signup.inputValidEmail': 'Email must be valid Email address format.',
  'auth.signup.inputValidPassword': 'Password must be at least 6 characters',
  'auth.signup.confirmPasswordNotMatch': 'Password and Password confirm do not match.',

  'auth.signup.additional.required': 'is required',
  'auth.signup.agree.prefix': 'By registration, I agree to',
  'auth.signup.agree.privacy': 'privacy policy',
  'auth.signup.agree.suffix': ' ',
  'auth.signup.agree.nda': 'NDA',
  'auth.signup.submit': 'Register',
  'auth.signup.loading': 'Loading Registration form',
  'auth.signup.blocked': 'Registration not allowed',

  'auth.error.userNotFound.title': 'User not found',
  'auth.error.userNotFound.description': 'Input your email address',
  'auth.error.password.title': 'Wrong password',
  'auth.error.password.description':
    'Please click "Forgot Password" link, if you forgot your password',
  'auth.error.multiSignIn.title': 'Multiple Signing in is not allowed.',
  'auth.error.multiSignIn.description':
    'Logged out because your account logged in from another device.  ',

  'page.welcome.registerButton': 'Registration',
  'page.welcome.aboutButton': 'Greetings',
  'page.welcome.liveButton': 'Live',
  'page.welcome.vodButton': 'Vod',
  'page.welcome.programButton': 'Program',
  'page.welcome.loginButton': 'Sign in',

  'page.about.title': 'Greetings',
  'page.about.registerButton': 'Registration',
  'page.about.programButton': 'Program',
  'page.about.empty': 'No greeting message',
  'page.about.loading': 'Loading Greetings...',

  'page.live.countdown.day': 'Days',
  'page.live.countdown.hour': 'Hours',
  'page.live.countdown.minutes': 'Minutes',
  'page.live.countdown.seconds': 'Seconds',
  'page.live.countdown.remains': 'remains',
  'page.live.countdown.message': `Live event started Please wait a moment`,
  'page.live.title': 'Live',
  'page.live.timeFormat': 'HH:mm',
  'page.live.viewMode.cinema': 'Cinema View',
  'page.live.viewMode.standard': 'Default View',
  'page.live.loading': 'Preparing Live Streaming..',
  'page.live.blocked': "You're not allowed to access live streaming.",
  'page.live.empty': 'There is no live streaming schedule now.',
  'page.live.fin': 'Live streaming has finished',

  'page.vod.title': 'VOD',
  'page.vod.play': 'PLAY',
  'page.vod.empty': 'Empty VOD List.',

  'page.program.title': 'Program',

  'page.speaker.title': 'Speaker',

  'page.survey.title': 'Survey',
  'page.survey.submit': 'Confirm',
  'page.survey.complete': 'You already finished this survey',
  'page.survey.complete-message': 'Thank you',
  'page.survey.empty': 'There is no survey on this symposium',

  'page.survey.answer.yes': 'Yes',
  'page.survey.answer.no': 'No',
  'page.survey.answer.noRank': 'No rank',

  'page.survey.loading': 'Loading Survey...',
  'page.survey.blocked': 'You have no permission to survey',

  'message.signinRequired': 'Signing in required',
  'message.signinRequiredPage': 'Signing in is required',

  'page.sponsor.title': 'Sponsor',
  'page.guide.title': 'Support',
  'page.gradeInformation.title': '평점 안내',

  'word.cancel': 'Cancel',

  'chat.askInputForm': 'Enter question',
  'chat.title': 'Live Q&A',
  'chat.send': 'Send',
  'chat.emptyMessage': 'There is no message yet',
  'chat.blocked': 'your account is not allowed to chat.',

  'firebase.emailExists': 'E-mail address aleady exists',
  'firebase.invalidEmail': 'Wrong email format',
  'firebase.weekPassword': 'password is weak',

  'page.title.welcome': 'Home',
  'page.title.support': 'Support',
  'page.title.seminar': 'Seminar',
  'page.title.signin': 'Sign in',
  'page.title.signup': 'Sign up',
  'page.title.forgotpassword': 'Forgot password',
  'page.title.survey': 'Survey',
}

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}
